function UlsanSvg({ onClick, gugun }) {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="110 20 900 850"
            enableBackground="new 0 55 600 900"
            id="ulsanSvg"
            style={{ filter: "url(#drop-shadow)" }}
            onClick={(evt) => onClick(evt)}
        >
            <defs>
                <filter id="drop-shadow" height="150%">
                    <feDropShadow dx="4" dy="4" stdDeviation="5" floodColor="#888888" />
                </filter>
                <linearGradient id="colorGradient2" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stopColor="#008CCE" stopOpacity={1} />
                    <stop offset="50%" stopColor="#36236B" stopOpacity={1} />
                    <stop offset="100%" stopColor="#C60026" stopOpacity={1} />
                </linearGradient>
            </defs>

            <g id="u1" data-code="31710" className={`${gugun == "31710" ? "on" : ""}`}>
                <path
                    id="u1_fill"
                    className="gungu_map_part on"
                    fill="#AAD7A5"
                    d="M174.313,400.182c3.629-2.87,6.848-5.491,10.156-7.994c1.327-1.004,3.251-2.185,4.185,0.057
							c2.927,7.026,11.251,9.585,13.602,17.289c1.138,3.722,2.481,6.778-0.327,10.078c-1.409,1.656-3.058,3.296-2.479,5.7
							c0.633,2.632,0.147,4.869-1.403,7.047c-0.657,0.925-1.202,2.27-0.51,3.303c0.909,1.354,2.448,0.746,3.674,0.416
							c4.504-1.211,8.987-2.258,13.733-2.032c5.364,0.254,6.497,1.194,5.758,6.438c-0.46,3.264,1.086,5.682,3.791,4.693
							c5.118-1.87,10.79-0.945,15.633-3.775c4.409-2.574,8.857-5.354,14.275-2.141c1.251,0.741,2.904,0.302,4.352-0.325
							c10.475-4.539,21.879-6.439,32.232-11.343c1.139-0.538,2.364-1.25,3.653,0.022c1.847,1.825,2.271,10.365,0.001,11.068
							c-6.776,2.095-5.026,8.174-6.511,12.581c-1.417,4.204,2.207,10.297,5.81,12.996c5.926,4.438,13.256,6.026,19.305,10.011
							c6.352,4.182,13.615,6.143,20.577,8.508c5.207,1.771,10.425,2.066,14.398,6.968c4.218,5.199,11.662,3.421,17.063,3.871
							c7.887,0.66,11.602,1.738,11.124,10.009c-0.179,3.098,0.063,5.787,2.87,7.915c1.85,1.401,1.947,3.813,1.479,6.017
							c-1.727,8.144-1.254,9.861,5.733,13.417c4.375,2.227,8.649,3.994,9.092,9.811c0.083,1.103,1.347,2.312,2.354,3.104
							c3.393,2.674,3.883,6.183,2.548,9.829c-1.063,2.897-0.01,4.973,2.255,5.682c11.438,3.58,14.839,16.277,24.762,21.51
							c0.582,0.309,0.805,1.424,1.059,2.206c2.244,6.913,7.448,10.374,13.986,12.688c3.218,1.141,6.103,3.104,8.564,5.768
							c4.606,4.979,9.566,9.615,16.147,12.039c1.538,0.567,3.266,1.189,4.083,2.715c1.828,3.414,4.302,2.681,6.484,0.858
							c2.313-1.929,4.654-2.102,7.419-1.658c5.646,0.902,11.237,1.079,16.123-2.707c0.993-0.769,2.155-1.021,3.301-0.729
							c3.271,0.831,5.818-0.672,8.248-2.451c7.32-5.367,8.813-5.119,13.742,2.822c1.271,2.05,4.58,4.216,3.229,6.235
							c-2.037,3.048-0.958,5.896-1.37,8.819c-1.295,9.204,2.677,12.078,11.342,13.258c4.629,0.631,7.309,3.188,8.346,7.336
							c0.953,3.815,2.618,6.544,6.32,8.312c3.292,1.57,5.159,9.242,2.596,11.339c-3.724,3.049-5.817,7.229-8.553,10.868
							c-2.016,2.683-6.363,2.771-6.813,5.828c-0.756,5.125-4.324,7.49-7.704,10.364c-3.211,2.732-3.793,6.732-1.809,9.903
							c3.069,4.9,7.33,9.239,12.842,11.322c6.139,2.322,12.12,5.347,19.432,2.433c5.153-2.055,11.021-3.476,16.915-1.578
							c2.367,0.763,2.502-1.362,3.09-2.895c1.479-3.857,6.872-5.515,10.338-4.05c2.799,1.183,6.123,1.711,9.163,1.548
							c2.357-0.127,4.055-1.925,3.177-5.043c-0.966-3.438,1.145-3.65,3.648-2.776c1.075,0.377,2.071,1.048,3.022,1.702
							c5.881,4.031,9.375,4.17,14.838-0.349c3.192-2.642,4.029-1.655,4.771,1.762c1.024,4.727,3.249,8.697,6.621,12.438
							c5.554,6.161,12.077,11.861,13.699,20.772c0.292,1.598,1.009,3.898,3.103,4.2c5.6,0.809,4.988,3.828,3.13,7.628
							c-0.646,1.325-1.144,2.841-1.233,4.299c-0.195,3.208-1.498,6.513,0.921,9.559c5.618,7.075,5.915,14.981,0.977,22.176
							c-3.385,4.932-3.081,5.649,2.754,7.072c1.728,0.42,3.229,0.178,4.8-0.605c1.492-0.745,2.678-0.854,4.416,0.479
							c5.063,3.884,10.377,7.457,17.485,5.725c3.58-0.875,5.175,1.354,3.771,4.818c-0.396,0.979-0.589,1.887-0.308,2.884
							c0.604,2.134,11.691,5.986,13.306,4.568c0.438-0.384,0.574-1.146,0.779-1.757c1.373-4.076,3.752-7.734,4.777-11.979
							c0.388-1.606,0.923-4.055,3.354-3.57c4.044,0.804,3.473-2.387,3.48-4.171c0.045-8.728,5.867-14.036,13.479-13.132
							c3.204,0.383,4.416-0.163,5.631-2.917c1.012-2.293,2.119-4.277,1.76-7.005c-0.646-4.898,2.471-7.012,6.739-8.241
							c5.69-1.64,9.673,1.07,12.828,5.12c1.409,1.811,1.708,1.89,3.544,0.53c3.592-2.662,7.189-5.232,12.438-2.995
							c2.899,1.232,7.757-3.053,8.468-6.105c0.608-2.614-2.904-3.692-2.346-5.69c1.423-5.059-2.68-6.429-5.449-7.543
							c-3.59-1.445-3.659-3.173-2.507-6.046c0.835-2.082,0.534-4.83-1.521-4.98c-3.668-0.27-5.679-6.228-10.455-2.715
							c-0.817,0.6-4.158-1.536-3.925-3.452c0.473-3.862-1.845-5.117-4.461-5.979c0.435-2.229,3.346-1.683,3.151-4.417
							c-0.236-3.367,1.446-6.399,3.271-9.219c0.706-1.092,1.338-1.961,0.911-3.588c-2.231-8.536-0.6-11.014,8.007-12.835
							c1.614-0.342,4.325-0.75,3.792-2.385c-1.412-4.317,1.023-9.639-3.471-13.123c-2.229-1.729-3.037-4.839,0.141-5.088
							c4.195-0.328,7.137-1.7,10.193-4.323c2.329-1.999,4.476-4.123,3.699-6.985c-0.724-2.675-3.727-1.179-5.54-0.956
							c-3.925,0.484-5.101-1.045-4.711-4.692c0.229-2.146,0.019-3.866-2.55-5.176c-2.04-1.042-3.235-3.715-3.531-6.305
							c-0.331-2.925,1.639-3.484,3.941-4.032c1.019-0.243,1.026-2.087,0.521-2.621c-2.163-2.271-1.437-5.313-2.444-7.862
							c-1.622-4.095-0.086-5.71,4.337-5.465c5.126,0.285,10.724,1.338,13.749-4.8c0.575-1.171,1.953-0.979,3.105-1.171
							c3.308-0.558,2.982-2.696,0.944-3.993c-5.316-3.379-6.751-7.716-4.206-13.71c-4.295,3.538-10.649,2.314-13.882,7.25
							c-0.228,0.346-1.52,0.496-1.808,0.215c-1.718-1.668-4.645-2.315-5.173-4.908c-0.559-2.733,2.256-2.841,3.854-3.765
							c1.58-0.912,3.212-1.813,4.93-2.389c3.121-1.047,3.529-3.722,4.095-6.306c0.411-1.874-1.296-2.903-2.25-2.138
							c-2.555,2.057-6.188,1.207-8.533,3.985c-2.62,3.101-6.923,1.729-7.685-2.277c-0.412-2.165-1.409-3.871-2.291-5.771
							c-0.974-2.095-5.012-6.22,1.583-7.333c2.127-0.358,0.952-2.161,0.761-3.279c-1.013-5.898-2.028-11.803-3.29-17.649
							c-0.407-1.889-1.121-4.647-2.46-5.223c-3.967-1.701-5.158-5.238-7.08-8.394c-0.492-0.809-1.293-1.789-2.114-1.978
							c-3.625-0.835-4.281-3.678-5.044-6.59c5.368-1.456,6.395-3.812,4.162-9.003c-1.54-3.587-4.443-5.737-7.776-7.322
							c-3.774-1.794-7.797-3.102-9.788-7.323c-0.889-1.882-2-1.925-3.845-0.512c-5.452,4.175-9.24,2.791-10.753-3.86
							c-0.867-3.815-1.205-7.716-6.026-2.811c-0.388,0.395-1.57,0.056-2.377-0.021c-3.991-0.37-6.623-2.656-8.199-6.162
							c-1.153-2.563-1.644-4.583-4.593-1.003c-2.216,2.691-5.445,3.105-8.367-0.339c-5.413-6.377-9.788-13.448-14.567-20.258
							c-2.653-3.784-5.388-7.35-5.529-12.118c-0.115-3.814-1.368-5.848-5.589-6.615c-3.05-0.555-7.557-1.617-7.041-6.152
							c0.364-3.233-1.046-3.559-3.297-4.188c-6.238-1.744-8.693-5.418-7.714-11.981c0.313-2.107-1.155-2.81-1.989-2.426
							c-2.741,1.259-4.23-0.78-6.317-1.514c-2.209-0.773-6.13-1.396-7.041-0.263c-2.876,3.575-4.409,0.752-6.92-0.042
							c-4.063-1.286-2.65-5.34-4.669-7.488c-2.686,2.229-13.344,3.478-14.477,1.177c-2.426-4.929-6.671-3.151-10.151-3.556
							c-1.929-0.224-3.843-0.931-5.854-0.741c-1.075,0.103-2.385-0.646-1.916-1.647c1.442-3.085,1.27-7.548,6.113-8.061
							c1.164-0.124,2.485-0.047,2.973-1.365c2.022-5.469,9.185-5.439,11.462-11.524c1.177-3.138,4.374-6.091,5.539-9.923
							c0.714-2.351,3.227-6.061,4.59-5.063c6.662,4.879,8.687,0.326,11.062-4.399c0.123-0.247,0.947-0.104,1.427-0.22
							c0.863-0.209,1.028-0.902,0.534-1.454c-1.65-1.833-2.137-5.396-4.632-5.579c-5.433-0.402-5.027-4.57-6.323-7.901
							c-1.46-3.753-1.901-7.341-0.288-11.17c0.385-0.916-0.112-2.286-0.416-3.39c-0.482-1.751-0.206-3.254,0.923-4.662
							c2.224-2.778,1.968-4.915-1.278-6.906c-2.807-1.72-4.479-4.562-4.229-7.952c0.188-2.565-0.843-4.781-1.177-7.061
							c-0.458-3.11-4.338-5.641-2.288-8.644c1.658-2.428,4.476,0.667,6.926,0.277c0.955-0.151,2.125,1.363,3.265,2.002
							c4.489,2.506,8.604,6.678,14.414,2.419c1.246-0.913,3.973-1.124,5.296-0.378c5.094,2.869,11.012,2.441,16.205,4.596
							c1.888,0.782,6.611,0.854,1.82,4.615c-0.714,0.562-0.83,2.26-0.029,3.278c2.618,3.324,5.621,6.498,10.289,4.873
							c2.244-0.779,4.207-0.45,7.608,0.492c-3.261-5.06-4.149-9.208-1.253-13.403c0.258-0.373,0.009-1.459-0.375-1.853
							c-4.388-4.494-3.459-10.815-5.653-16.018c-1.158-2.746-0.746-4.921,0.911-6.863c1.688-1.977,2.549-4.175,3.014-6.618
							c0.919-4.842,6.407-7.71,5.738-13.279c-0.04-0.337,0.965-0.807,1.491-1.205c5.092-3.859,4.481-13.338-1.313-16.155
							c-3.298-1.602-4.586-4.372-3.972-7.151c0.555-2.506,0.219-4.859,0.362-7.253c0.152-2.57,0.641-4.596,2.534-6.618
							c2.92-3.121,3.279-6.86,1.646-11.063c-1.604-4.12-2.97-8.512-1.597-13.05c1.391-4.6-0.79-7.027-4.917-7.893
							c-2.191-0.459-4.231-1.568-4.714-3.209c-1.452-4.931-6.138-5.627-9.479-8.031c-0.729-0.528-1.94-1.682-3.089-0.459
							c-3.09,3.29-5.283,0.951-7.488-1.157c-1.766-1.688-3.854-3.401-6.058-1.498c-4.328,3.739-8.085,1.888-11.923-0.551
							c-1.269-0.807-2.444-1.73-4.009-1.875c-8.998-0.831-10.708-9.107-13.763-14.957c-3.271-6.266-1.337-13.13,3.949-18.612
							c3.301-3.423,3.807-13.578,1.814-17.991c-1.051-2.326-3.212-4.843-0.477-7.752c5.287-5.621,5.851-13.793,1.512-19.888
							c-0.721-1.016-0.911-2.077-1.225-3.19c-2.055-7.283-6.212-10.466-13.771-10.307c-4.021,0.083-8.091,0.31-11.672-1.709
							c-3.992-2.25-8.121-2.576-12.459-1.89c-2.725,0.432-3.895-0.674-4.311-3.39c-0.823-5.354-3.533-7.336-8.916-6.225
							c-2.348,0.484-3.929-0.335-5.436-1.577c-3.435-2.831-5.46-6.396-10.831-8.014c-5.487-1.653-12.212-5.571-14.583-12.77
							c-0.694-2.111-2.724-1.554-4.131-0.357c-2.677,2.273-6.213,3.625-6.469,8.208c-0.136,2.432-3.551,4.867-5.367,3.65
							c-5.613-3.758-10.877-7.889-17.87-8.777c-0.467-0.06-1.067-0.862-1.204-1.417c-0.833-3.372-3.45-4.414-6.481-5.47
							c-0.345,3.294-2.813,5.176-3.832,7.735c-3.271,8.219-8.326,11.081-16.264,8.605c-1.858-0.579-3.449-0.344-5.296-0.074
							c-3.454,0.504-8.104,2.242-10.165-1.076c-4.562-7.345-10.364-12.467-18.355-15.786c-3.064-1.273-5.712-2.085-8.409-0.575
							c-2.479,1.389-3.701,4.467-6.435,5.793c-0.119,0.059,0.066,1.352,0.448,1.733c2.807,2.804,1.054,4.892-0.794,7.237
							c-1.16,1.472-2.849,2.907-2.166,5.269c0.985,3.408-1.864,3.72-3.751,3.724c-2.731,0.005-5.741-0.302-8.129-1.489
							c-3.144-1.563-4.405-1.083-5.03,2.26c-0.271,1.448-1.005,2.811-1.539,4.208c-1.931,5.049-9.745,7.379-14.198,4.108
							c-1.728-1.269-3.881-1.481-5.123-0.505c-6.54,5.147-15.22,2.742-22.317,7.048c-5.466,3.316-12.504,3.972-16.485,10.03
							c-1.178,1.791-3.071,2.966-5.25,3.602c-6.179,1.799-9.368,6.879-12.121,11.996c-2.868,5.332-4.766,11.179-8.737,15.923
							c-2.623,3.134-2.225,6.662,0.714,8.811c3.468,2.534,1.938,4.212,0.074,6.279c-1.447,1.605-3.027,3.093-4.48,4.693
							c-1.188,1.307-1.845,2.763-0.82,4.521c2.902,4.986,0.976,10.192,0.315,15.206c-0.335,2.551-1.021,6.463,0.623,6.754
							c4.364,0.771,4.413,4.531,6.375,6.874c2.757,3.29,4.401,7.475,8.13,9.996c9.314,6.3,9.229,12.887,0.109,19.103
							c-2.987,2.037-4.55,1.398-6.476-1.162c-2.294-3.05-4.705-6.393-8.82-6.88c-9.405-1.115-18.017-5.125-27.198-7.155
							c-7.498-1.657-6.025-1.41-7.951,4.345c-0.365,1.095-0.631,2.246-0.767,3.392c-1.177,9.936-8.206,16.944-12.634,25.247
							c-0.678,1.272-2.32,1.492-3.588,0.912c-3.897-1.783-7.848-3.517-11.525-5.695c-3.542-2.096-10.905-0.448-11.729,3.546
							c-1.558,7.554-6.872,10.99-13.142,13.711c-5.365,2.326-9.067,6.521-13,10.813c1.932,1.296,4.27,2.017,5.06,3.542
							c3.235,6.249,8.365,5.38,13.656,4.204c6.144-1.365,12.024,2.857,13.308,8.89c0.964,4.539,0.709,8.889,0.127,13.352
							c-0.229,1.755-0.771,3.566,0.895,5.125c2.278,2.133,1.983,4.675,0.922,7.357c-0.851,2.151-2.115,4.453-1.985,6.615
							c0.353,5.875,0.023,11.271-4.895,15.415c-0.507,0.428-0.716,1.616-0.566,2.35c1.164,5.754-3.051,7.201-6.92,7.342
							c-5.716,0.207-8.44,4.461-12.141,7.36c-6.042,4.733-9.656,11.729-14.246,17.622c-5.897,7.575-14.313,7.733-21.521,10.687
							c-6.308,2.583-12.678,4.848-18.534,8.409c-1.292,0.786-1.24,1.688-0.81,2.948c0.738,2.16,1.302,4.271,2.67,6.275
							C166.617,389.767,171.695,393.856,174.313,400.182z"
                ></path>
                <text
                    transform="matrix(1 0 0 1 444.2952 380.0559)"
                    fontFamily="''Do Hyeon', sans-serif;'"
                    fontSize="27"
                >
                    울주군
                </text>
            </g>
            <g id="u4" data-code="31110" className={`${gugun == "31110" ? "on" : ""}`}>
                <path
                    id="u4_fill"
                    className="gungu_map_part"
                    fill="#AAD7A5"
                    d="M728.979,291.341c-3.921,0.759-6.219,3.885-9.457,1.938c-2.413-1.448-3.136-0.473-3.361,2.023
                c-0.296,3.306-2.222,6.12-5.51,6.077c-5.051-0.063-8.007,3.003-10.91,6.052c-5.804,6.102-9.767,5.718-13.258-1.939
                c-1.26-2.763-3.203-3.405-5.978-4.095c-5.547-1.38-8.115,6.049-13.573,4.007c-0.146,0.622-0.454,1.258-0.403,1.865
                c0.185,2.167,0.574,4.246,2.27,5.896c1.341,1.308,2.837,3.875,2.179,4.762c-3.56,4.788-0.188,9.017,0.452,13.444
                c0.141,0.977,0.266,1.957,0.461,2.923c0.368,1.819-0.988,2.926-1.989,1.869c-3.649-3.841-8.121-2.289-12.354-2.611
                c-4.785-0.366-9.09-5.739-8.246-10.462c0.449-2.51-1.1-2.954-2.632-3.309c-4.329-0.999-8.736-1.455-12.892-3.381
                c-1.938-0.898-4.709-0.716-6.082,1.806c-1.146,2.105-2.773,1.648-4.205,0.813c-3.961-2.313-9.021-2.438-12.23-6.258
                c-0.61-0.726-1.675-1.191-2.711-0.584c-1.276,0.747-0.234,1.763-0.221,2.656c0.061,3.854,2.657,7.308,1.494,11.462
                c-0.364,1.309,1.062,3.122,2.435,4.114c5.048,3.649,5.543,6.276,2.547,11.688c-0.63,1.139-0.252,2.91-0.117,4.37
                c0.083,0.925,1.082,1.792,0.327,2.787c-3.705,4.876,1.463,8.937,1.25,13.5c-0.035,0.755,0.627,1.561,1.623,1.603
                c3.354,0.139,4.507,2.857,6.287,5.04c4.344,5.325,7.353,11.354,17.063,9.255c8.538-1.845,16.311,4.612,23.117,9.597
                c2.904,2.127,5.258,5.063,8.859,6.498c5.939,2.363,11.535-1.26,11.338-7.527c-0.176-5.592,4.065-8.653,9.553-7.027
                c11.768,3.488,23.531,7.104,36.007,3.496c0.733-0.211,1.652,0.015,2.438,0.219c9.122,2.376,18.241,1.846,27.588,0.044
                c-0.562-1.066-1.777-2.479-1.479-2.97c2.237-3.675,0.324-7.059-0.344-10.542c-0.313-1.628,0.158-3.227,1.716-3.776
                c6.168-2.171,4.787-6.724,3.489-11.05c-1.153-3.839-2.514-7.595-1.675-11.668c0.923-4.476,0.025-8.62-2.312-12.424
                c-3.059-4.978-4.331-10.369-4.268-16.14c0.037-3.322,0.251-6.65,0.113-9.967c-0.348-8.383-3.588-10.658-11.71-8.289
                c-1.571,0.457-3.188,1.074-4.508,2.009c-2.733,1.937-5.161,3.265-8.534,0.939C727.47,297.898,726.696,295.466,728.979,291.341z"
                ></path>
                <text
                    transform="matrix(1 0 0 1 675.6716 358.1028)"
                    fontFamily="''Do Hyeon', sans-serif;'"
                    fontSize="27"
                >
                    중구
                </text>
            </g>
            <g id="u5" data-code="31140" className={`${gugun == "31140" ? "on" : ""}`}>
                <path
                    id="u5_fill"
                    className="gungu_map_part"
                    fill="#AAD7A5"
                    d="M687.336,389.537c0.583,2.056,0.106,4.563-2.513,5.78c-4.257,1.98-8.68,3.192-13.417,1.267
                c-7.185-2.923-11.327-10.104-18.914-12.793c-4.903-1.737-9.295-3.893-14.608-2.327c-3.127,0.921-5.88-0.286-8.365-2.162
                c-5.601-4.228-8.096-3.432-8.919,3.436c-0.47,3.924-2.135,2.961-4.217,1.822c-1.01-0.553-1.886-1.362-2.795-2.088
                c-2.638-2.102-4.217-1.007-5.216,1.734c-2.784,7.633-5.718,15.08-14.054,18.618c-1.364,0.579-2.348,2.336-3.217,3.729
                c-0.866,1.386-2.018,2.422-3.495,2.509c-2.828,0.167-4.804,1.717-4.416,4.195c0.345,2.208,3.107,0.769,4.736,1.206
                c3.831,1.031,8.459-1.393,11.333,4.149c1.427,2.752,6.719,0.808,8.955-0.248c4.604-2.177,6.726-2.239,7.443,2.877
                c0.306,2.162,1.362,2.189,2.745,3.085c2.728,1.765,4.62-0.524,6.905-0.57c4.622-0.095,8.566,2.782,13.391,2.392
                c2.781-0.225,5.534,2.036,2.771,5.696c-1.475,1.95-0.373,3.584,0.728,4.913c1.672,2.019,3.68,3.633,6.361,4.482
                c2.629,0.832,5.836,2.415,5.03,5.568c-0.624,2.436-0.184,2.107,1.641,3.041c3.255,1.667,8.736,1.004,9.221,6.01
                c0.562,5.809,3.583,10.137,6.361,14.884c4.065,6.945,8.724,13.371,13.875,19.524c2.655,3.169,4.166,2.06,5.943-0.695
                c2.012-3.119,4.263-2.889,6.144,0.516c0.799,1.442,1.36,3.056,2.339,4.354c1.7,2.258,3.974,3.502,6.875,2.38
                c6.504-2.518,7.089-2.24,8.494,4.389c1.259,5.928,3.194,6.787,7.903,3.022c2.472-1.977,4.118-2.404,5.906,0.599
                c1.554,2.604,4.07,3.823,6.75,5.318c6.582,3.677,13.121,7.796,13.529,16.944c0.117,2.648,2.598,4.999,6.133,4.065
                c2.132-0.563,4.235-0.797,4.573,2.034c0.517,4.313,2.13,7.457,5.6,10.563c3.715,3.322,5.156,8.776,8.671,12.732
                c3.401,3.829,5.421,4.597,9.374,1.271c5.222-4.396,10.331-8.919,15.484-13.393c0.874-0.758,1.464-1.617,1.979-2.734
                c1.965-4.278,4.487-8.06,10.196-6.78c1.367,0.305,1.604-0.788,2.272-1.442c5.464-5.335,2.017-13.08,5.003-19.321
                c0.743-1.549,0.639-4.033,2.471-5.737c1.17-1.09,3.363-2.154,1.881-4.419c-4.25-6.486-8.584-12.916-12.845-19.396
                c-1.644-2.503-3.373-2.663-5.758-0.848c-2.884,2.189-5.398,6.38-9.915,2.624c-7.126,2.546-6.951-3.66-8.554-7.376
                c-1.194-2.771-2.678-4.729-5.629-5.776c-3.112-1.104-6.067-2.664-9.042-4.126c-1.123-0.552-1.877-1.52-0.975-2.823
                c0.721-1.042,1.695-0.791,2.64-0.255c4.242,2.411,8.871,3.625,13.597,4.692c1.824,0.412,3.775,1.407,4.698,3.354
                c1.971,4.157,5.05,5.32,9.359,3.838c5.705-1.968,11.353-4.471,17.206,0.021c1.863,1.429,2.73-0.257,3.2-2.207
                c1.796-7.476-3.734-13.834-3.057-21.262c0.237-2.588-2.91-3.71-5.892-4.02c-3.391-0.35-7.173-0.865-9.871-2.675
                c-2.07-1.39-0.419-4.894-0.144-7.451c0.124-1.139-0.24-2.289-1.345-2.286c-4.084,0.009-6.524-3.451-10.1-4.521
                c-4.766-1.421-9.462-3.081-14.161-4.715c-1.318-0.458-3.6-0.712-2.975-2.566c0.668-1.979,2.51-0.536,3.813-0.116
                c5.376,1.735,10.696,3.65,16.129,5.188c1.672,0.474,4.583,1.792,5.195-0.787c1.074-4.519,4.548-9.737-1.955-13.38
                c-1.23-0.689-1.936-2.267-3-3.313c-3.68-3.614-5.793-9-11.646-10.19c-1.248-0.254-1.418-1.582-0.662-2.381
                c3.322-3.516-0.066-3.856-2.178-4.847c-6.003-2.813-12.825-3.584-18.477-7.191c-3.071-1.962-6.218-2.308-9.521-1.38
                c-1.646,0.462-3.282,0.865-4.896,0.564c-7.681-1.429-15.263-1.823-23.154-1.045c-9.263,0.913-18.422-1.367-27.339-4.199
                C688.685,381.091,687.115,382.394,687.336,389.537z"
                ></path>
                <text
                    transform="matrix(1 0 0 1 700.9744 449.9973)"
                    fontFamily="''Do Hyeon', sans-serif;'"
                    fontSize="27"
                >
                    남구
                </text>
            </g>
            <g id="u3" data-code="31200" className={`${gugun == "31200" ? "on" : ""}`}>
                <path
                    id="u3_fill"
                    className="gungu_map_part"
                    fill="#AAD7A5"
                    d="M886.618,176.487c-3.396,2.736-5.995,0.808-9.093-0.933c-5.859-3.29-12.281-5.51-17.822-9.495
                c-3.748-2.692-7.721-3.977-12.535-1.737c-2.432,1.132-6.566,2.055-7.854-1.53c-2.291-6.38-6.97-7.56-12.747-7.728
                c-1.949-0.057-3.974-0.627-5.8-1.369c-7.997-3.243-15.972-5.13-24.371-1.28c-2.961,1.357-5.349,0.795-6.1-3.019
                c-0.247-1.255-1.125-2.73-2.604-2.724c-4.909,0.026-7.698-4.484-8.986-7.083c-2.814-5.679-7.121-4.611-11.359-4.986
                c-5.205-0.459-10.405,0.641-13.21,5.107c-2.972,4.734-7.441,7.748-10.959,11.789c-2.169,2.491-5.416,3.556-8.573,3.092
                c-3.668-0.538-4.119,1.437-4.505,4.106c-0.273,1.888,0.395,4.267-3.273,4.235c-0.869-0.008-5.081,3.479-2.026,7.339
                c2.136,2.699,0.507,4.502-2.924,5.646c-4.086,1.361-9.242-1.016-12.439,3.626c-0.477,0.691-2.599,0.721-2.815-0.807
                c-0.562-3.958-2.139-4.882-5.633-2.701c-0.85,0.528-1.707-0.736-2.388-1.442c-0.81-0.841-1.979-2.308-2.78-1.766
                c-3.851,2.602-8.394,4.92-10.867,8.573c-1.88,2.776-1.044,7.403-1.38,11.21c-0.202,2.299,0.152,4.402-3.611,4.443
                c-3.423,0.037-7.695,1.773-6.619,6.08c0.781,3.129,0.132,5.771,0.058,8.636c-0.094,3.658-0.616,7.537,1.237,10.777
                c3.061,5.346,1.334,9.764-1.666,14.251c-3.229,4.828-0.972,10.286-1.534,15.427c-0.193,1.77,2.162,1.705,3.352,2.454
                c4.658,2.93,6.322,14.281,2.536,18.229c-0.984,1.029-3.417,1.884-3.32,2.553c0.824,5.769-5.49,8.468-5.642,13.811
                c-0.046,1.631-1.131,3.625-2.374,4.752c-2.902,2.629-1.835,5.638-0.189,7.571c1.88,2.205,4.485,1.133,6.967-0.632
                c5.805-4.129,13.898-1.559,16.638,4.919c1.425,3.369,5.274,3.681,7.914,0.711c2.975-3.345,6.185-7.512,10.586-7.522
                c4.146-0.01,5.361-1.05,5.649-4.673c0.238-3.003,2.418-5.178,4.927-4.027c3.169,1.452,5.269,0.181,7.836-0.796
                c3.404-1.297,5.416,0.243,4.578,3.656c-0.478,1.941-1.036,3.41,1.021,4.698c2.03,1.268,3.698,0.812,5.454-0.544
                c4.278-3.305,9.262-3.379,14.297-2.787c2.405,0.285,4.85,1.444,5.365,3.821c0.729,3.345,1.754,6.795,1.057,10.305
                c-1.517,7.63-0.365,14.833,3.523,21.521c1.99,3.423,2.854,6.967,2.772,10.904c-0.102,4.776-0.452,9.502,1.617,14.2
                c1.195,2.715,2.538,7.35,0.161,8.978c-5.868,4.018-4.354,9.114-4.278,14.518c0.057,4.07,3.271,10.34,7.079,11.616
                c8.126,2.722,16.499,4.955,21.899,12.588c1.172,1.659,3.559,3.198,5.725,2.09c5.687-2.909,9.015,1.856,11.684,4.495
                c6.406,6.33,11.737,13.898,17.021,21.095c4.644,6.324,10.585,7.347,16.397,8.975c4.218,1.183,7.828,1.729,9.705,6.088
                c0.273,0.639,1.188,1.369,1.838,1.4c1.391,0.064,0.926-1.466,1.424-2.215c1.386-2.085-1.088-4.535,0.134-6.188
                c2.685-3.636,0.554-5.182-2.083-7.137c-2.395-1.775-4.754-3.384-7.68-4.182c-2.55-0.698-3.977-2.521-2.94-5.176
                c0.896-2.299,1.985-4.674,3.565-6.521c3.285-3.836,4.032-7.979,2.564-12.64c-0.595-1.881-0.679-3.686,0.771-5.109
                c2.797-2.752,2.979-5.669,1.804-9.354c-1.924-6.025-0.576-8.01,5.064-11.524c3.429-2.135,5.935-5.32,4.623-9.859
                c-0.372-1.292-0.485-2.359,0.596-3.093c5.55-3.767,9.246-10.086,16.263-11.821c1.919-0.476,4.223-0.403,5.698-1.477
                c6.329-4.601,14.124-2.813,21.066-4.948c3.747-1.152,8.386,0.996,10.235-4.527c0.283-0.841,1.339-0.744,2.173-0.871
                c2.97-0.451,4.382-2.119,4.777-5.239c0.404-3.173,0.616-6.327-0.297-9.311c-0.833-2.726-0.142-4.262,2.475-4.625
                c3.122-0.436,4.039-2.216,4.231-5.056c0.178-2.649,1.404-4.403,4.552-3.776c5.63,1.123,6.858,0.019,5.743-5.629
                c-0.896-4.538-1.169-9.019-0.538-13.6c0.803-5.864,0.002-11.608-1.835-17.189c-0.43-1.303-0.488-3.345-2.317-3.501
                c-6.175-0.528-6.49-5.031-5.977-9.359c0.463-3.894,0.18-5.129-4.184-4.016c-5.315,1.357-9.447-2.606-7.645-7.571
                c1.352-3.722-0.325-4.819-2.704-6.166c-7.816-4.425-10.212-12.2-12.311-20.08c-0.58-2.178-0.964-4.733,1.263-6.47
                c1.748-1.363,1.479-2.673,0.366-4.253c-3.503-4.979-1.81-10.458-1.302-15.767c0.252-2.652,1.66-4.458,4.744-4.797
                c1.508-0.166,3.207-0.325,4.537-1.701c3.463-3.584,2.843-6.565-2.334-10.093c-5.521-3.763-8.659-3.688-14.063,0.544
                c-1.896,1.486-3.21,1.022-4.663-0.622C887.627,180.695,887.557,178.632,886.618,176.487z"
                ></path>
                <text
                    transform="matrix(1 0 0 1 794.2009 268.8733)"
                    fontFamily="''Do Hyeon', sans-serif;'"
                    fontSize="27"
                >
                    북구
                </text>
            </g>
            <g id="u2" data-code="31170" className={`${gugun == "31170" ? "on" : ""}`}>
                <path
                    id="u2_fill"
                    className="gungu_map_part"
                    fill="#AAD7A5"
                    d="M895.142,516.281c1.939-3.787,4.207-6.592,6.595-9.333c1.386-1.589,2.565-3.618,2.353-5.884
                c-0.33-3.491,0.851-5.821,4.359-6.732c1.053-0.271,2.104-0.736,1.23-1.954c-0.603-0.84-2.372-2.311-2.63-1.493
                c-1.29,4.067-5.263,2.779-7.685,4.387c-2.507,1.663-4.839,1.851-6.954-0.523c-2.847-3.191-4.077-6.754-2.437-10.985
                c1.1-2.843,2.454-6.754,5.564-6.103c4.369,0.917,7.58-1.156,11.302-1.947c1.813-0.385,3.247-0.764,3.18-2.981
                c-0.064-2.029-0.168-3.646-2.709-4.627c-3.976-1.536-6.672-0.044-9.38,2.474c-0.887,0.823-1.712,2.342-3.324,1.307
                c-1.4-0.897-2.953-1.877-2.861-3.794c0.133-2.818,0.474-5.629,0.792-8.435c0.164-1.452,0.938-2.592,2.46-2.738
                c4.454-0.428,9.323-1.329,13.161,0.872c3.029,1.738,3.696,0.067,4.204-1.511c2.367-7.374,3.039-15.241,6.495-22.314
                c0.656-1.342-0.542-1.73-1.412-2.115c-1.282-0.569-1.314,0.596-1.439,1.282c-0.763,4.147-3.719,4.547-7.123,4.323
                c-1.72-0.112-3.432-0.102-3.595,2.313c-0.09,1.323-0.662,2.328-2.187,1.927c-1.276-0.337-1.758-1.397-1.775-2.688
                c-0.055-4.49-0.116-8.982-0.188-13.476c-0.021-1.288,0.315-2.511,1.764-2.536c5.657-0.093,11.253-2.6,16.718-0.54
                c3.295-7.7-1.257-16.636,3.989-24.072c0.296-0.418,0.254-1.304,0.026-1.819c-1.691-3.844-0.191-7.006,1.391-10.559
                c1.772-3.983,1.522-8.885,5.73-11.724c0.221-0.148-0.069-0.961,0-1.447c0.828-5.941-3.148-11.499-1.424-17.529
                c0.061-0.213-0.563-0.708-0.945-0.949c-2.768-1.746-3.346-4.869-4.547-7.514c-2.778-6.12-3.172-6.116-8.178-1.493
                c-1.551,1.431-2.771,4.144-5.22,3.908c-4.38-0.424-8.767,1.33-12.622,1.033c-7.896-0.604-14.305,2.733-20.869,5.397
                c-6.555,2.658-13.311,5.947-13.949,14.91c-0.258,3.605-2.263,7.236-5.967,8.583c-5.175,1.88-5.453,5.421-3.674,9.458
                c1.644,3.729,0.823,6.845-1.509,9.563c-1.707,1.989-1.954,3.615-0.685,5.785c1.053,1.798,0.242,3.568,0.047,5.481
                c-0.533,5.17-4.487,8.288-6.236,12.64c-0.814,2.028-0.385,3.054,1.352,3.216c4.394,0.413,6.617,4.245,10.131,6.021
                c1.996,1.008,2.856,3.595,2.151,5.122c-1.485,3.215-1.062,6.463-1.446,9.704c-0.481,4.046-3.696,5.689-5.964,2.669
                c-3.437-4.579-7.102-7.361-12.903-6.958c-0.419,0.028-0.983-0.345-1.294-0.696c-2.056-2.336-4.54-1.82-7.462-1.424
                c2.566,4.868,6.479,8.427,7.809,13.59c1.845,7.162,1.418,14.171-0.5,21.161c-0.604,2.208-0.436,3.735,2.152,3.768
                c7.051,0.085,10.707,4.491,14.232,9.911c2.443,3.752,3.258,6.509,0.948,10.705c-5.79,10.521-6.106,21.206-0.003,32.007
                c1.529,2.711,2.574,4.513,5.732,3.187c3.188-1.333,7.277-7.485,5.99-9.197c-4.426-5.891,1.881-7.251,4.182-10.133
                c2.188-2.746,4.959-1.908,7.433-0.075c0.86,0.64,1.657,1.196,2.662,0.495c0.827-0.576,0.395-1.312,0.28-2.147
                c-0.601-4.309,1.729-5.925,5.981-5.248C891.252,508.547,892.325,512.646,895.142,516.281z"
                ></path>
                <text transform="matrix(1 0 0 1 868.781 407.3801)" fontFamily="''Do Hyeon', sans-serif;'" fontSize="27">
                    동구
                </text>
            </g>
        </svg>
    );
}

export default UlsanSvg;
