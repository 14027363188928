import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import msg from "../../../common/message";
import CommonModal from "../../../common/CommonModal";
import axios from "axios";
import { getUserRole } from "../../../../CommonFunction";

export default function ContentModal(props) {
    const { mode, data, close, render, allData, retry, sgg, collectYn } = props;
    const [files, setFiles] = useState([]);
    const baseUrl = "/api/data/facility/file";

    const insertToUpdate = (formData) => {
        if (formData === undefined) return;

        if (mode === "insert") {
            let submit = new FormData();
            Object.keys(formData).forEach((key) => {
                if (key == "files") {
                    if (formData[key].length == 0) return;

                    [...formData[key]].forEach((file) => {
                        submit.append("files", file); // Append files without an index
                    });
                } else {
                    submit.append(key, formData[key] || "");
                }
            });

            return axios
                .post(baseUrl, submit, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    Swal.fire(msg.alertMessage["insert_success"]);
                    close(false);
                    render();
                });
        } else {
            delete formData.files;
            return axios
                .patch(baseUrl, formData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then(() => {
                    Swal.fire(msg.alertMessage["insert_success"]);
                    close(false);
                    render();
                });
        }
    };

    // 삭제
    const [del, setDel] = useState({ id: null, del: false });
    useEffect(() => {
        if (!del.del) return;
        Swal.fire(msg.alertMessage["double_check"]).then((res) => {
            if (res.isConfirmed) {
                axios
                    .delete(baseUrl + "/" + data.id)
                    .then(() => {
                        Swal.fire(msg.alertMessage["delete"]);
                        close(true);
                        render();
                    })
                    .catch((err) => {
                        return alert(err.response.data.message);
                    });
            } else {
                setDel({ id: null, del: false });
                return;
            }
        });
    }, [del]);

    // 모달 input 생성
    const [li, setLi] = useState([]);
    useEffect(() => {
        let liArr = [
            {
                id: "dataNm",
                label: "데이터명",
                type: "input",
                input_type: "text",
                readonly: true,
                placeholder: "구/군_주차유형_주차시설 표준_yyyyMM",
                col: "12",
                // required: true,
                // msg: "필수입력 값 입니다.",
            },
            {
                id: "comment",
                label: "비고",
                type: "textarea",
                col: "12",
            },
            {
                id: "year",
                label: "연도",
                type: "yearPicker",
                col: "6",
            },
            {
                id: "month",
                label: "월",
                type: "select",
                option: [
                    { label: "1", value: "01" },
                    { label: "2", value: "02" },
                    { label: "3", value: "03" },
                    { label: "4", value: "04" },
                    { label: "5", value: "05" },
                    { label: "6", value: "06" },
                    { label: "7", value: "07" },
                    { label: "8", value: "08" },
                    { label: "9", value: "09" },
                    { label: "10", value: "10" },
                    { label: "11", value: "11" },
                    { label: "12", value: "12" },
                ],
                col: "6",
            },
            {
                id: "sggCd",
                label: "구군",
                type: "select",
                // disabled: mode === "update" ? true : false,
                option: [
                    ...sgg.map((reg) => ({
                        label: reg.value,
                        value: reg.name,
                    })),
                ],
                col: "6",
            },
            {
                // 표준관리대장 기준. 1:공영노상, 2:공영노외, 3:공영부설, 4:민영노상, 5:민영노외, 6:민영부설, 7:부설, 8:부설개방, 9:사유지개방
                id: "lotType",
                label: "주차유형",
                type: "select",
                option: [
                    // { label: "공영주차장", value: "1" },
                    { label: "민영노외", value: "5" },
                    // { label: "노외주차장", value: 3 },
                    // { label: "부설주차장", value: 4 },
                    { label: "부설개방", value: "8" },
                    { label: "사유지개방", value: "9" },
                    // { label: "표준 데이터셋", value: "7" },
                ],
                col: "6",
            },
            // {
            //     id: "dupType",
            //     label: "중복 여부",
            //     type: "input",
            //     col: 6,
            //     readonly: true,
            // },
            {
                id: "dupInfo",
                label: "중복 행",
                type: "input",
                col: "6",
                readonly: true,
            },
            {
                id: "files",
                label: "파일 업로드",
                type: "files",
                col: "6",
                required: true,
                msg: "1개의 파일을 업로드해야 합니다.",
                disabled: mode === "update" ? true : false,
                uploadName: "주차시설 업로드",
                downloadName: "주차시설",
                files: files,
                setFiles: (files) => setFiles(Array.from(files)),
            },
        ];

        setLi(liArr);
    }, [files]);

    return (
        <CommonModal
            list={li}
            mode={mode}
            data={data}
            close={close}
            insertToUpdate={insertToUpdate}
            setDel={setDel}
            deleteAuth={(getUserRole() == "ROLE_ADM" || getUserRole() == "ROLE_SGG_ADM") && collectYn == "N"}
            updateAuth={(getUserRole() == "ROLE_ADM" || getUserRole() == "ROLE_SGG_ADM") && collectYn == "N"}
        />
    );
}
