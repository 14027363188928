import React, { useEffect, useRef, useState } from "react";
import CrdnPrfmncTable from "./CrdnPrfmncTable";
import CrdnNocsTable from "./CrdnNocsTable";

function Prfmnc() {
    const [type, setType] = useState({ name: "총괄", type: "prfmnc" });
    const [menu, setMenu] = useState(1);

    const crdnPrfmncTabList = [
        { name: "총괄", type: "prfmnc" },
        { name: "고정식", type: "prfmnc" },
        { name: "이동식", type: "prfmnc" },
        { name: "인력단속", type: "prfmnc" },
        { name: "버스탑재형", type: "prfmnc" },
        { name: "안전신문고", type: "prfmnc" },
    ];

    const crdnNocsTabList = [
        { name: "단속건수", type: "nocs" },
        { name: "견인건수", type: "nocs" },
    ];

    useEffect(() => {
        if (menu === 1) {
            setType({ name: "총괄", type: "prfmnc" });
        } else if (menu === 2) {
            setType({ name: "단속건수", type: "nocs" });
        }
    }, [menu]);

    return (
        <div id={"prfmnc"} className={"pageWrap"}>
            <div className="tab_wrap">
                <ul className="tab">
                    <li
                        onClick={() => {
                            setMenu(1);
                        }}
                        className={`${menu === 1 ? "on" : ""}`}
                    >
                        불법주정차 단속실적
                    </li>
                    <li
                        onClick={() => {
                            setMenu(2);
                        }}
                        className={`${menu === 2 ? "on" : ""}`}
                    >
                        차종별 단속건수 및 견인건수
                    </li>
                </ul>
                {menu === 1 && (
                    <ul className="tab">
                        {crdnPrfmncTabList.map((ele) => {
                            return (
                                <li
                                    key={ele.name}
                                    className={`${ele.name === type.name ? "on" : ""}`}
                                    onClick={() => setType(ele)}
                                >
                                    {ele.name}
                                </li>
                            );
                        })}
                    </ul>
                )}
                {menu === 2 && (
                    <ul className="tab">
                        {crdnNocsTabList.map((ele) => {
                            return (
                                <li
                                    key={ele.name}
                                    className={`${ele.name === type.name ? "on" : ""}`}
                                    onClick={() => setType(ele)}
                                >
                                    {ele.name}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
            <div className="table_wrap">
                {type.type == "prfmnc" && <CrdnPrfmncTable type={type} />}
                {type.type == "nocs" && <CrdnNocsTable type={type} />}
            </div>
        </div>
    );
}

export default Prfmnc;
