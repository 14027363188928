import React, { useEffect, useState, useCallback, useRef } from "react";
import Swal from "sweetalert2";
import msg from "../../../common/message";

import { AgGridReact } from "ag-grid-react";

import axios from "axios";
import { useCodeTree } from "../../../../CommonHook";
//
import ContentSearch from "./ContentSearch.js";
import Modal from "../../../common/Modal";
import ContentModal from "./ContentModal.js";
import { getUserRole } from "../../../../CommonFunction";

export default function FileUpload() {
    const [render, setRender] = useState(true);
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    // const btn_search = document.getElementById("btn_search");데

    // 지역 코드 가져오기
    const { tree: sgg, set: setSgg } = useCodeTree({ parentNm: "31000", deps: [] });

    // 그리드용 매핑 배열
    const sggMap = sgg.reduce((result, item) => {
        result[item.name] = item.value;
        return result;
    }, {});

    // column setting
    const columnDefs = [
        { field: "seq", headerName: "번호", valueGetter: "node.rowIndex + 1", sortable: true, width: "90" },
        { field: "year", flex: 0.5, headerName: "연도", sortable: true },
        { field: "month", flex: 0.5, headerName: "월", sortable: true },
        {
            field: "sggCd",
            flex: 0.5,
            headerName: "구군",
            valueFormatter: (params) => {
                const sggNm = sggMap[params.value];
                return sggNm || params.value; // 매핑된 값이 없을 경우 기존 값 사용
            },
        },
        { field: "dataNm", flex: 2, headerName: "데이터명" },
        {
            field: "collectYn",
            flex: 0.7,
            headerName: "데이터승인",
            valueFormatter: (params) => {
                return params.value == "Y" ? "완료" : "대기";
            },
            cellStyle: (params) => {
                return {
                    color: params.value == "N" ? "red" : "black",
                    // fontWeight: params.value == "N" ? "bold" : "normal",
                };
            },
        },
        { field: "createId", flex: 0.5, headerName: "등록자" },
        { field: "createDtm", flex: 0.7, headerName: "등록일시", sortable: true },
    ];

    const defaultColDef = {
        resizable: true,
        autoHeight: true,
    };

    // search 에서 쓰는 api 를 게시판에도 재사용, 별도 호출 없음.
    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    // cell click event
    const cellClickedListener = useCallback((evt) => {
        if (evt.event.target.closest("button")) return;
        // 데이터 수집 이벤트
        if (evt.colDef.field == "collectYn" && evt.value == "N") {
            Swal.fire(msg.alertMessage["double_check_collect"]).then((res) => {
                if (res.isConfirmed) {
                    const docId = evt.data.id;
                    axios("/api/data/illegal/data/collect/" + docId)
                        .then(() => {
                            // Swal.fire(msg.alertMessage["insert_success"]);
                            document.querySelector("button").click();
                        })
                        .catch((err) => {
                            alert("오류가 있습니다. 파일 상태를 체크하고 이후 담당자에 문의해주세요.");
                            console.log("에러 발생", err.response.data.message);
                        });
                } else {
                    return;
                }
            });
        } else {
            if (evt.data.state == "-1") {
                setProc({ task: "proc", mode: "insert", retry: true });
            } else {
                setProc({ task: "proc", mode: "update" });
            }
            setRow(evt.data);
            setModalOpen(true);
        }
    }, []);

    // modal
    const [proc, setProc] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const onOpenModal = () => {
        setRow({});
        setProc({ task: "proc", mode: "insert" });
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div>
            <div className="pageWrap">
                <ContentSearch setData={setData} render={render} sgg={sgg} />

                <div className="btnWrap flxRit">
                    <button className="btn btn_write" onClick={onOpenModal}>
                        등록
                    </button>
                </div>

                <div className="ag-theme-alpine" style={{ width: "100%", height: "450px" }}>
                    <AgGridReact
                        headerHeight={40}
                        rowData={data}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection="multiple"
                        onGridReady={onGridReady}
                        onCellClicked={cellClickedListener}
                        pagination={true}
                        paginationPageSize={10}
                    />
                </div>
            </div>

            <Modal open={modalOpen} close={closeModal} header="불법주정차 단속 - 파일 업로드">
                <ContentModal
                    mode={proc.mode}
                    retry={proc.retry}
                    data={row}
                    close={closeModal}
                    render={() => setRender(!render)}
                    sgg={sgg}
                />
            </Modal>
        </div>
    );
}
