import { useEffect, useState, useMemo } from "react";
import { useRecoilState, atom } from "recoil";
import axios from "axios";

const cachedCodeState = atom({
    key: "cachedCodeState",
    default: {},
});

// 코드 트리 hook
export function useCodeTree({ parentNm, deps }) {
    const [tree, set] = useState([]);
    const [cachedResult, setCachedResult] = useRecoilState(cachedCodeState);

    useEffect(() => {
        if (cachedResult[parentNm]) {
            set(cachedResult[parentNm]);
        } else {
            axios
                .get(`/api/system/code/codes/search/${parentNm}`)
                .then((res) => {
                    const json = res.data;

                    const sortedChildren = json.children && json.children.sort((a, b) => a.name - b.name);
                    set(sortedChildren);
                    setCachedResult({ ...cachedResult, [parentNm]: sortedChildren });
                })
                .catch((err) => {});
        }
    }, [parentNm, cachedResult, setCachedResult]);

    return { tree, set };
}
